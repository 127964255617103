import {
	addTriggerAction,
	AnyTriggerAction,
	fetchTriggerActions,
	removeTriggerAction,
	testTriggerAction,
	updateTriggerAction,
} from '@requests/triggers'
import { ITriggerAction } from '@touchpoints/requests'
import { autorun, makeAutoObservable, runInAction } from 'mobx'
import type { RootStore } from './root'

export class TriggerActionsStore {
	private readonly root: RootStore

	readonly list: ITriggerAction[] = []

	constructor(root: RootStore) {
		this.root = root

		makeAutoObservable(this)

		autorun(() => {
			this.fetchTriggerActions()
		})
	}

	get actionsForAction() {
		const result: Record<string, string[]> = {}

		for (const action of this.list) {
			const actionId = action.id
			const references = action.triggerActions ?? []

			for (const ref of references) {
				const actions = result[ref.referenceId] ?? []
				actions.push(actionId)
				result[ref.referenceId] = actions
			}
		}

		return result
	}

	async fetchTriggerActions() {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const res = await fetchTriggerActions(orgId)

		if (!res) {
			return
		}

		const { actions } = res.data ?? {}

		if (!actions) {
			return
		}

		runInAction(() => {
			for (const action of actions) {
				this.addAction(action)
			}
		})
	}

	async create(
		data: Omit<AnyTriggerAction, 'id' | 'createdAt' | 'createdBy' | 'organizationId'>
	) {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const res = await addTriggerAction(orgId, data)

		if (!res) {
			return
		}

		const { action } = res.data ?? {}

		if (!action) {
			return
		}

		this.addAction(action)

		return action
	}

	async update(id: string, data: AnyTriggerAction) {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const res = await updateTriggerAction(orgId, id, data)

		if (!res) {
			return
		}

		const { action } = res.data ?? {}

		if (!action) {
			return
		}

		this.addAction(action)
	}

	async delete(id: string) {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const res = await removeTriggerAction(orgId, id)

		if (!res) {
			return
		}

		this.removeAction(id)
	}

	getById(id: string) {
		return this.list.find((t) => t.id === id)
	}

	async test(
		id: string,
		options: { positionId: string; candidateId: string; orgUserId?: string }
	) {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return { success: false, message: 'No active organization' }
		}

		const res = await testTriggerAction(orgId, id, options)
		if (!res) {
			return { success: false, message: 'No response' }
		}

		const success = res.success ?? false

		if (!success) {
			return { success: false, message: res.message ?? 'Failed to test action' }
		}

		return { success }
	}

	private addAction(action: ITriggerAction) {
		const existing = this.getById(action.id)

		if (existing) {
			Object.assign(existing, action)
		} else {
			this.list.push(makeAutoObservable(action))
		}
	}

	private removeAction(id: string) {
		const index = this.list.findIndex((t) => t.id === id)

		if (index < 0) {
			return
		}

		this.list.splice(index, 1)
	}
}
