import type { AnyTriggerAction, ITriggerAction } from '@touchpoints/requests'
import { authHeader, get, patch, post, del } from './api'

export async function fetchTriggerActions(organizationId: string) {
	const headers = await authHeader()
	return get<{ actions: ITriggerAction[] }>(
		`/organizations/${organizationId}/trigger-actions`,
		undefined,
		headers
	)
}

export async function addTriggerAction(
	organizationId: string,
	data: Omit<AnyTriggerAction, 'id' | 'createdAt' | 'createdBy' | 'organizationId'>
) {
	const headers = await authHeader()
	return post<{ action: ITriggerAction }>(
		`/organizations/${organizationId}/trigger-actions`,
		{
			...data,
		},
		headers
	)
}

export async function updateTriggerAction(
	organizationId: string,
	actionId: string,
	data: AnyTriggerAction
) {
	const headers = await authHeader()
	return patch<{ action: ITriggerAction }>(
		`/organizations/${organizationId}/trigger-actions/${actionId}`,
		{
			...data,
		},
		headers
	)
}

export async function removeTriggerAction(organizationId: string, actionId: string) {
	const headers = await authHeader()
	return del(`/organizations/${organizationId}/trigger-actions/${actionId}`, headers)
}

export async function testTriggerAction(
	organizationId: string,
	actionId: string,
	data: { positionId: string; candidateId: string; orgUserId?: string }
) {
	const headers = await authHeader()
	return post(`/organizations/${organizationId}/trigger-actions/${actionId}/test`, data, headers)
}

export type { AnyTriggerAction }
